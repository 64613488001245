import { Box, Button, Col, ProcessingBar, Row } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';

import TableSelected from './TableSelected';
import TableUsers from './TableUsers';

export default ({
  isLoadingUsers,
  onChangeRecipients,
  onClickProceed,
  selectedRecipients = [],
  usersColumns,
  users = [],
}) => {
  const { t } = useTranslation();

  return (
    <>
      {isLoadingUsers && <ProcessingBar />}

      <Box height={4} />

      <Row>
        <Col lg={8}>
          <TableUsers
            columns={usersColumns}
            data={users?.filter(
              (item) =>
                !selectedRecipients.find(
                  (selectedRecipient) => selectedRecipient.id === item.id
                )
            )}
            isLoading={isLoadingUsers}
            onChangeRecipients={onChangeRecipients}
          />
        </Col>

        <Col lg={4}>
          <Button
            isFullWidth
            mb={4}
            text={t('proceedWithSelection')}
            onClick={onClickProceed}
          />

          <TableSelected
            data={selectedRecipients}
            noDataText={t('noRecipientsSelected')}
            onChangeRecipients={onChangeRecipients}
          />
        </Col>
      </Row>
    </>
  );
};
