import {
  Box,
  Button,
  Container,
  Divider,
  Modal,
  ToggleTabs,
} from 'octiv-components';
import { usePaging, useToggle } from 'octiv-hooks';
import { useRegionsFind } from 'octiv-hooks/requests/Regions';
import { useTenantsFind, useTenantsUpdate } from 'octiv-hooks/requests/Tenants';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import FormQuery from './FormQuery';
import Locations from './locations';
import TableTenants from './TableTenants';
import Tenant from './tenant';

export const views = {
  ACCOUNT: 'account',
  LOCATIONS: 'locations',
};

export default () => {
  const { t } = useTranslation();

  const [toggle, setToggle, resetToggle] = useToggle();
  const [selectedModalView, setSelectedModalView] = useState(views.ACCOUNT);
  const [paging, setPaging] = usePaging();
  const [query, setQuery] = useState({
    regionId: undefined,
    statusId: 1,
    search: undefined,
  });

  const { data: regions, isFetching: isFetchingRegions } = useRegionsFind({
    filter: { isActive: true },
    paging: { perPage: -1 },
    include: 'timezones,currencies',
  });

  const {
    data: tenants,
    isFetching: isFetchingTenants,
    refetch: getTenantsRequest,
  } = useTenantsFind(
    {
      filter: query,
      include: 'region,nonDeactivatedUsersCount',
      paging,
    },
    {
      onSuccess: (response) => setPaging(response?.meta?.paging),
    }
  );

  const { isLoading: isUpdatingTenantStatus, mutate: putTenantStatusRequest } =
    useTenantsUpdate({
      onSuccess: getTenantsRequest,
    });

  const onClickAction = ({ action, id }) => {
    switch (action) {
      case 'edit':
        setToggle({
          type: 'update',
          data: (tenants?.data || []).find((item) => item.id === id),
        });
        break;

      case 'activate':
        putTenantStatusRequest({ id, statusId: 1 });
        break;

      case 'deactivate':
        if (window.confirm(t('areYouSureProceed'))) {
          putTenantStatusRequest({ id, statusId: 2 });
        }
        break;

      case 'suspend':
        if (window.confirm(t('areYouSureProceed'))) {
          putTenantStatusRequest({ id, statusId: 3 });
        }
        break;

      case 'delete':
        if (window.confirm(t('areYouSureProceed'))) {
          putTenantStatusRequest({ id, statusId: 4 });
        }
        break;

      default:
        break;
    }
  };

  return (
    <>
      {toggle.isVisible && (
        <Modal
          renderAboveContent={() =>
            toggle.type === 'update' ? (
              <ToggleTabs
                activeKey={selectedModalView}
                mt={1}
                options={[
                  { label: 'Account', key: views.ACCOUNT },
                  { label: 'Locations', key: views.LOCATIONS },
                ]}
                px={4}
                setActiveKey={setSelectedModalView}
              />
            ) : null
          }
          title={`${
            toggle.type === 'create' ? t('create') : t('update')
          } Facility`}
          onClose={resetToggle}
        >
          {selectedModalView === views.ACCOUNT ? (
            <Tenant
              tenantId={toggle.data?.id}
              onSuccessCreate={() => {
                resetToggle();
                getTenantsRequest();
              }}
            />
          ) : (
            <Locations
              tenantId={toggle.data?.id}
              timezones={
                regions?.data?.find(
                  (item) => item.id === toggle.data?.region?.id
                )?.timezones
              }
            />
          )}
        </Modal>
      )}

      <Container
        appBarProps={{
          title: t('manage'),
          breadcrumbs: ['Facilities'],
          children: (
            <Box ml='auto'>
              <Button
                text='Create Facility'
                onClick={() => setToggle({ type: 'create', data: {} })}
              />
            </Box>
          ),
        }}
        isLoading={isFetchingTenants || isUpdatingTenantStatus}
      >
        <FormQuery
          initialValues={query}
          isFetchingRegions={isFetchingRegions}
          regions={regions?.data || []}
          onSubmit={setQuery}
        />

        <Divider pb={4} />

        <TableTenants
          data={tenants?.data || []}
          isLoading={isFetchingTenants}
          paging={tenants?.paging || {}}
          query={query}
          setPaging={setPaging}
          onClickAction={onClickAction}
          onClickRegion={(regionId) =>
            setQuery((prev) => ({ ...prev, regionId }))
          }
        />
      </Container>
    </>
  );
};
