import {
  AccountsDebitBatchControl,
  AccountsGoCardlessMandatesImport,
  AccountsLocationBilling,
  Changelog,
  SuperAdminBenchmarks,
  SuperAdminDashboard,
  SuperAdminExerciseCategories,
  SuperAdminExportLocations,
  SuperAdminExportUsers,
  SuperAdminLocationCategories,
  SuperAdminPrivacyPolicy,
  SuperAdminRegions,
  SuperAdminRemoteConfig,
  SuperAdminTermsOfUse,
  SuperAdminUsers,
  TenantsImport,
  TenantsManage,
  UserProfile,
  UserTenants,
} from 'octiv-containers';
import React from 'react';

import { getStacksPublic } from './Public';

export const getStacksSuperAdmin = ({ t, onLoginPath }) => [
  {
    title: t('dashboard'),
    icon: 'dashboard',
    screens: [
      {
        name: 'Dashboard',
        path: '/dashboard',
        element: <SuperAdminDashboard />,
      },
    ],
  },

  {
    title: t('accounts'),
    icon: 'money',
    screens: [
      {
        name: 'AccountsDebitBatchControl',
        path: '/accounts/debit-batch-control',
        element: <AccountsDebitBatchControl />,
        options: {
          title: t('debitBatchControl'),
        },
      },
      {
        name: 'AccountsLocationBilling',
        path: '/accounts/location-billing',
        element: <AccountsLocationBilling />,
        options: {
          title: t('locationBilling'),
        },
      },
      {
        name: 'AccountsGoCardlessMandatesImport',
        path: '/accounts/gocardless-mandates-import',
        element: <AccountsGoCardlessMandatesImport />,
        options: {
          title: t('goCardlessMandatesImport'),
        },
      },
    ],
  },

  {
    title: t('tenants'),
    icon: 'storefront',
    screens: [
      {
        name: 'TenantsManage',
        path: '/facilities/manage',
        element: <TenantsManage />,
        options: {
          title: t('manage'),
        },
      },
      {
        name: 'TenantsImport',
        path: '/facilities/import',
        element: <TenantsImport />,
        options: {
          title: t('import'),
        },
      },
    ],
  },

  {
    title: t('users'),
    icon: 'assignment_ind',
    screens: [
      {
        name: 'SuperAdminUsers',
        path: '/users',
        element: <SuperAdminUsers />,
      },
    ],
  },

  {
    title: t('exports'),
    icon: 'file_download',
    screens: [
      {
        name: 'SuperAdminExportUsers',
        path: '/exports/users',
        element: <SuperAdminExportUsers />,
        options: {
          title: t('users'),
        },
      },
      {
        name: 'SuperAdminExportLocations',
        path: '/exports/locations',
        element: <SuperAdminExportLocations />,
        options: {
          title: t('locations'),
        },
      },
    ],
  },

  {
    title: t('settings'),
    icon: 'settings',
    screens: [
      {
        name: 'SettingsSuperAdminRegions',
        path: '/settings/regions',
        element: <SuperAdminRegions />,
        options: {
          title: t('regions'),
        },
      },
      {
        name: 'SettingsSuperAdminLocationCategories',
        path: '/settings/location-categories',
        element: <SuperAdminLocationCategories />,
        options: {
          title: t('locationCategories'),
        },
      },
      {
        name: 'SuperAdminExerciseCategories',
        path: '/settings/exercise-categories',
        element: <SuperAdminExerciseCategories />,
        options: {
          title: t('exerciseCategories'),
        },
      },
      {
        name: 'SuperAdminBenchmarks',
        path: '/settings/benchmarks',
        element: <SuperAdminBenchmarks />,
        options: {
          title: t('benchmarks'),
        },
      },
      {
        name: 'SettingsSuperAdminPrivacyPolicy',
        path: '/settings/privacy-policy',
        element: <SuperAdminPrivacyPolicy />,
        options: {
          title: t('privacyPolicy'),
        },
      },
      {
        name: 'SettingsSuperAdminTermsOfUse',
        path: '/settings/terms-of-use',
        element: <SuperAdminTermsOfUse />,
        options: {
          title: t('termsOfUse'),
        },
      },
      {
        name: 'SettingsSuperAdminRemoteConfig',
        path: '/settings/remote-config',
        element: <SuperAdminRemoteConfig />,
        options: {
          isVisibleNavigation: false,
          isVisibleInNavigation: false,
        },
      },
    ],
  },

  {
    options: {
      isVisibleInNavigation: false,
    },
    screens: [
      {
        name: 'UserProfile',
        path: '/user/profile',
        element: <UserProfile />,
      },
      {
        name: 'UserTenants',
        path: '/user/accounts',
        element: <UserTenants />,
      },
      {
        name: 'Changelog',
        path: '/changelog',
        element: <Changelog />,
      },
    ],
  },

  ...getStacksPublic({ onRedirectPath: '/dashboard', onLoginPath }),
];
