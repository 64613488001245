import { Images } from 'octiv-assets';
import { Box, Image } from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { useLeadsFind } from 'octiv-hooks/requests/Leads';
import {
  getDateReadableDayMonthYear,
  getFullName,
} from 'octiv-utilities/Functions';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Tables from '../tables';
import FormQuery from './FormQuery';

export default ({ selectedRecipients, onChangeRecipients, onClickProceed }) => {
  const { t } = useTranslation();

  const {
    selectedLocation,
    tenant: { locationOptionsAll },
  } = useActiveUserTenant();

  const [query, setQuery] = useState({
    locationId: selectedLocation?.id,
    status: undefined,
    search: undefined,
    isMinimalData: true,
    isRedacted: false,
  });

  const { data: users, isFetching: isLoadingUsers } = useLeadsFind({
    filter: query,
    paging: { perPage: -1 },
    sort: 'name',
  });

  return (
    <>
      <FormQuery
        initialValues={query}
        locationOptions={locationOptionsAll}
        onSubmit={setQuery}
      />

      <Tables
        isLoadingUsers={isLoadingUsers}
        selectedRecipients={selectedRecipients}
        users={
          users?.data?.map((item) => ({
            ...item,
            id: item?.user?.id,
          })) || []
        }
        usersColumns={[
          {
            accessor: 'source',
            Cell: ({ row: { original } }) => {
              const isDiscoveryLead = original.source === 'discovery' || true;
              return isDiscoveryLead ? (
                <Box marginRight='-2rem'>
                  <Image
                    alt={t('discoveryLogo')}
                    backgroundSize='contain'
                    height={4}
                    mr={1}
                    src={Images.vitalityLogomark}
                    width={4}
                  />
                </Box>
              ) : null;
            },
          },
          {
            Header: t('name'),
            id: 'name',
            Cell: ({ row: { original } }) => getFullName(original),
          },
          {
            Header: t('lastContacted'),
            accessor: 'lastContactedDate',
            Cell: ({ value }) => getDateReadableDayMonthYear({ date: value }),
          },
          {
            Header: t('nextFollowUp'),
            accessor: 'nextFollowUpDate',
            Cell: ({ value }) => getDateReadableDayMonthYear({ date: value }),
          },
        ]}
        onChangeRecipients={onChangeRecipients}
        onClickProceed={onClickProceed}
      />
    </>
  );
};
