import { Table, Text } from 'octiv-components';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, locationOptions, onClickAction, ...props }) => {
  const { t } = useTranslation();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('location'),
        accessor: 'locationId',
        Cell: ({ value }) => {
          return locationOptions.find((location) => location.value === value)
            ?.label;
        },
      },
      {
        Header: 'CREF',
        id: 'cref',
        accessor: 'settings',
        Cell: ({ value }) => value.cref,
      },
      {
        Header: 'Dev ID',
        id: 'devId',
        accessor: 'settings',
        Cell: ({ value }) => value.devId,
      },
      {
        Header: 'Dev Token',
        id: 'devToken',
        accessor: 'settings',
        Cell: ({ value }) => value.devToken,
      },
      {
        Header: t('action'),
        Cell: ({
          row: {
            original: {
              settings: { cref, id },
            },
          },
        }) => (
          <Text
            as='span'
            color={cref ? 'primary' : 'danger'}
            onClick={() => onClickAction({ id })}
          >
            {cref ? t('edit') : t('onboardNow')}
          </Text>
        ),
      },
    ],
    [onClickAction, locationOptions, t]
  );

  return <Table {...props} columns={tableColumns} data={tableData} />;
};
