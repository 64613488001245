import { Box, Chip, Modal, ProcessingBar } from 'octiv-components';
import { usePaging, useToggle } from 'octiv-hooks';
import {
  useProgrammesCreate,
  useProgrammesFind,
  useProgrammesUpdate,
  useProgrammesUpdateActivateDeactivate,
} from 'octiv-hooks/requests/Programmes';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Setting from '../../components/Setting';
import Form from './Form';
import FormQuery from './FormQuery';
import Table from './Table';

export default () => {
  const { t } = useTranslation();

  const [toggle, setToggle, resetToggle] = useToggle();
  const [paging, setPaging] = usePaging();

  const [query, setQuery] = useState({
    isActive: true,
  });

  const {
    data: settings,
    isFetching: isFetchingSettings,
    refetch: getSettingsRequest,
  } = useProgrammesFind({
    filter: query,
    paging,
  });

  const { isLoading: isCreatingSetting, mutate: postSettingRequest } =
    useProgrammesCreate({
      onSuccess: () => {
        resetToggle();
      },
      meta: {
        useOnSuccessToast: true,
      },
    });

  const { isLoading: isUpdatingSetting, mutate: putSettingRequest } =
    useProgrammesUpdate({
      onSuccess: () => {
        getSettingsRequest();
        resetToggle();
      },
      meta: {
        useOnSuccessToast: true,
      },
    });

  const {
    isLoading: isUpdatingActivationSetting,
    mutate: putActivationSettingRequest,
  } = useProgrammesUpdateActivateDeactivate({
    onSuccess: getSettingsRequest,
    meta: {
      useOnSuccessToast: true,
    },
  });

  const onPutPostRequest = (values) => {
    if (toggle.type === 'create') {
      postSettingRequest(values);
    } else {
      putSettingRequest({ id: toggle.data.id, ...values });
    }
  };

  const onClickAction = ({ action, id }) => {
    switch (action) {
      case 'edit':
        setToggle({
          type: 'update',
          data: settings?.data?.find((item) => item.id === id),
        });
        break;

      case 'toggleActivation':
        putActivationSettingRequest({ id });
        break;

      default:
        break;
    }
  };

  return (
    <>
      {isUpdatingActivationSetting && <ProcessingBar />}

      {toggle.isVisible && (
        <Modal
          isSidebar
          title={`${toggle.type === 'create' ? t('create') : t('update')} ${t(
            'programme'
          )}`}
          onClose={resetToggle}
        >
          <Form
            initialValues={toggle.data}
            isLoading={isUpdatingSetting || isCreatingSetting}
            onSubmit={onPutPostRequest}
          />
        </Modal>
      )}

      <Setting
        boxTitleContainerRenderRight={() => (
          <Box ml='auto'>
            <Chip
              hasAlternateBackground
              icon='add'
              title={t('createNew')}
              onClick={() => setToggle({ type: 'create' })}
            />
          </Box>
        )}
        title={t('programmes')}
      >
        <FormQuery initialValues={query} onSubmit={setQuery} />

        <Box height={4} />

        <Table
          hasAlternateBackground
          data={settings?.data || []}
          isLoading={isFetchingSettings}
          paging={settings?.paging}
          setPaging={setPaging}
          onClickAction={onClickAction}
        />
      </Setting>
    </>
  );
};
