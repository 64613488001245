import _ from 'lodash';
import { Box, Icon, ProcessingSuspense, Text, Tooltip } from 'octiv-components';
import { useMediaQuery } from 'octiv-hooks';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSortBy, useTable } from 'react-table';

import ProcessingSpinner from '../processing/spinner';
import { StyledTable } from './Styled';

const renderSelect = ({ row, isAllSelected, onSelect, onSelectAll }) => {
  const renderSelectIcon = ({ isSelected, onClick }) => {
    return (
      <Icon
        stopPropagation
        isActive={isSelected}
        name={isSelected ? 'check_box' : 'check_box_outline_blank'}
        onClick={onClick}
      />
    );
  };

  if (row && row.original)
    return renderSelectIcon({
      isSelected: row.original.isSelected,
      onClick: () => onSelect(row.original),
    });

  return renderSelectIcon({
    isSelected: isAllSelected,
    onClick: () => onSelectAll({ isAllSelected }),
  });
};

const Table = ({
  columns,
  data = [],
  hasAlternateBackground,
  hasPadding = true,
  hiddenColumns = undefined,
  highlightColor = 'danger',
  highlightTestFunc,
  initialState = {},
  isLoading = false,
  isPaginationAllHidden = false,
  isVisibleHeader = true,
  isVisiblePaginationBottom = true,
  isVisiblePaginationTop = true,
  noDataText,
  onClickRow = undefined,
  onSelect = undefined,
  onSelectAll = undefined,
  onSortBy = undefined,
  paging = undefined,
  setPaging = undefined,
  sortBy = undefined,
  ...props
}) => {
  const { t } = useTranslation();
  const { xsDown, mdDown } = useMediaQuery();

  const padding = xsDown ? 3 : mdDown ? 4 : 6;

  // Plugin specific requirements
  let tableOptions = {};
  const tablePlugins = [];

  if (onSelect) {
    columns.unshift({
      id: 'selection',
      Header: renderSelect,
      Cell: renderSelect,
    });

    tableOptions = {
      ...tableOptions,
      isAllSelected: data?.every((item) => item.isSelected),
      manualRowSelectedKey: 'isSelected',
      onSelect,
      onSelectAll,
    };
  }

  if (onSortBy) {
    tableOptions = {
      ...tableOptions,
      manualSortBy: true,
      disableMultiSort: true,
      disableSortRemove: true,
      autoResetSortBy: false,
    };
    tablePlugins.push(useSortBy);
  }

  const {
    // General
    getTableBodyProps,
    getTableProps,
    headerGroups,
    prepareRow,
    rows,
    setHiddenColumns,
    setSortBy,
  } = useTable(
    {
      autoResetHiddenColumns: false,
      columns,
      data,
      ...tableOptions,
      initialState: {
        ...(hiddenColumns ? { hiddenColumns } : {}),
        ...(sortBy ? { sortBy } : {}),
        ...initialState,
        ...tableOptions.initialState,
      },
    },
    ...tablePlugins
  );

  // Update hidden columns when hiddenColumns change
  const isFirstRenderHiddenColumns = useRef(true);
  useEffect(() => {
    if (isFirstRenderHiddenColumns.current) {
      isFirstRenderHiddenColumns.current = false;
      return;
    }

    setHiddenColumns(hiddenColumns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hiddenColumns]);

  // Update sort when sortBy change
  const isFirstRenderSortBy = useRef(true);
  useEffect(() => {
    if (isFirstRenderSortBy.current) {
      isFirstRenderSortBy.current = false;
      return;
    }

    setSortBy(sortBy);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy]);

  const canRenderDetails = !_.isEmpty(data);

  const renderPagination = () => {
    return (
      <Box isFlex alignItems='center' height={10} px={padding}>
        <Text lineHeight={1} variant='caption'>
          {t('pagingResults', {
            offsetStart: paging.currentPageOffsetStart,
            offsetEnd: paging.currentPageOffsetEnd,
            numberOfResults: paging.numberOfResults,
          })}
        </Text>

        <Box ml='auto'>
          {!xsDown && (
            <Text lineHeight={1} mr={1} variant='caption'>
              {`${t('perPage')}:`}
            </Text>
          )}
        </Box>

        <Tooltip
          minWidth={20}
          options={[
            { label: '10', value: 10 },
            { label: '25', value: 25 },
            { label: '50', value: 50 },
            { label: '75', value: 75 },
            { label: '100', value: 100 },
            ...(isPaginationAllHidden ? [] : [{ label: t('all'), value: -1 }]),
          ]}
          placement='bottom'
          pointerEvents={isLoading ? 'none' : 'auto'}
          onClick={
            setPaging
              ? ({ value }) => setPaging({ page: 1, perPage: value })
              : undefined
          }
        >
          <Box isFlex alignItems='center' pl={2}>
            <Text lineHeight={1} variant='caption'>
              {paging.perPage === -1 ? t('all') : paging.perPage}
            </Text>

            <Icon color='grey1' name='arrow_drop_down' />
          </Box>
        </Tooltip>

        <Text lineHeight={1} ml={padding} mr={2} variant='caption'>
          {`${xsDown ? '' : `${t('page')} `}${paging.currentPage} / ${
            paging.numberOfPages
          }`}
        </Text>

        <Box
          isDisabled={!setPaging || !paging.previousPage}
          onClick={
            setPaging && paging.previousPage
              ? () => setPaging({ page: paging.previousPage })
              : undefined
          }
        >
          <Icon name='chevron_left' />
        </Box>

        <Box
          isDisabled={!setPaging || !paging.nextPage}
          onClick={
            setPaging && paging.nextPage
              ? () =>
                  setPaging({
                    page: paging.nextPage,
                  })
              : undefined
          }
        >
          <Icon name='chevron_right' />
        </Box>

        <Box mb={1} ml={1} size={4}>
          {isLoading && <ProcessingSpinner />}
        </Box>
      </Box>
    );
  };

  const renderRows = () =>
    rows.map(
      (row) =>
        prepareRow(row) || (
          <tr
            className={
              highlightTestFunc && highlightTestFunc(row)
                ? 'highlighted'
                : undefined
            }
            {...row.getRowProps(
              onClickRow ? { onClick: () => onClickRow(row) } : undefined
            )}
          >
            {row.cells.map((cell) => (
              <td
                {...cell.getCellProps()}
                style={{
                  ...(cell?.column?.id === 'selection'
                    ? { width: '24px' }
                    : {}),
                  ...(cell?.column?.whiteSpaceWrap
                    ? {
                        whiteSpace: 'normal',
                      }
                    : {}),
                }}
              >
                {cell.render('Cell')}
              </td>
            ))}
          </tr>
        )
    );

  return (
    <>
      {isVisiblePaginationTop &&
        canRenderDetails &&
        paging !== undefined &&
        renderPagination()}

      <StyledTable
        bg={hasAlternateBackground ? 'grey5' : 'grey6'}
        hasAlternateBackground={hasAlternateBackground}
        hasHoverState={!!onClickRow}
        hasPadding={hasPadding}
        highlightColor={highlightColor}
        mediaQueryPadding={padding}
        xsDown={xsDown}
        {...props}
      >
        {canRenderDetails ? (
          <table {...getTableProps()}>
            {isVisibleHeader && (
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps(
                          !!column.sortBy && !!column.getSortByToggleProps
                            ? column.getSortByToggleProps({
                                onClick: async () => {
                                  await column.toggleSortBy();

                                  onSortBy({
                                    sort: column.isSorted
                                      ? `${column.isSortedDesc ? '-' : ''}${
                                          column.sortBy
                                        }`
                                      : undefined,
                                    sortBy: column.isSorted
                                      ? column.sortBy
                                      : undefined,
                                    order: column.isSorted
                                      ? column.isSortedDesc
                                        ? 'DESC'
                                        : 'ASC'
                                      : undefined,
                                  });
                                },
                              })
                            : undefined
                        )}
                      >
                        <Box isFlex alignItems='center'>
                          {column.render('Header')}

                          {!!column.sortBy && (
                            <Icon
                              color={column.isSorted ? 'primary' : 'grey4'}
                              ml={1}
                              name={
                                column.isSortedDesc
                                  ? 'arrow_drop_down'
                                  : 'arrow_drop_up'
                              }
                            />
                          )}
                        </Box>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
            )}

            <tbody {...getTableBodyProps()}>{renderRows()}</tbody>
          </table>
        ) : isLoading ? (
          <table>
            {isVisibleHeader && (
              <thead>
                <tr>
                  <th>
                    <ProcessingSuspense
                      bg={hasAlternateBackground ? 'grey4' : 'grey5'}
                      items={[{ width: 12 }]}
                    />
                  </th>

                  <th>
                    <ProcessingSuspense
                      bg={hasAlternateBackground ? 'grey4' : 'grey5'}
                      items={[{ width: 14 }]}
                    />
                  </th>

                  <th>
                    <ProcessingSuspense
                      bg={hasAlternateBackground ? 'grey4' : 'grey5'}
                      items={[{ width: 10 }]}
                    />
                  </th>
                </tr>
              </thead>
            )}

            <tbody>
              <tr>
                <td>
                  <ProcessingSuspense
                    bg={hasAlternateBackground ? 'grey4' : 'grey5'}
                    items={[{ width: 16 }]}
                  />
                </td>

                <td>
                  <ProcessingSuspense
                    bg={hasAlternateBackground ? 'grey4' : 'grey5'}
                    items={[{ width: 18 }]}
                  />
                </td>

                <td>
                  <ProcessingSuspense
                    bg={hasAlternateBackground ? 'grey4' : 'grey5'}
                    items={[{ width: 14 }]}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <ProcessingSuspense
                    bg={hasAlternateBackground ? 'grey4' : 'grey5'}
                    items={[{ width: 16 }]}
                  />
                </td>

                <td>
                  <ProcessingSuspense
                    bg={hasAlternateBackground ? 'grey4' : 'grey5'}
                    items={[{ width: 18 }]}
                  />
                </td>

                <td>
                  <ProcessingSuspense
                    bg={hasAlternateBackground ? 'grey4' : 'grey5'}
                    items={[{ width: 14 }]}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        ) : (
          <Box isFlex alignItems='center' height={14} justifyContent='center'>
            <Text color='grey2' variant='subheading'>
              {noDataText === undefined ? t('noData') : noDataText}
            </Text>
          </Box>
        )}
      </StyledTable>

      {isVisiblePaginationBottom &&
        canRenderDetails &&
        paging !== undefined &&
        renderPagination()}
    </>
  );
};

export default Table;
