import { Table, Text, Tooltip } from 'octiv-components';
import { getDateReadableDayMonthYear } from 'octiv-utilities/Functions';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, query, onClickAction, onClickRegion, ...props }) => {
  const { t } = useTranslation();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('name'),
        accessor: 'name',
      },
      {
        Header: t('region'),
        accessor: 'region',
        Cell: ({ value }) => (
          <Text onClick={() => onClickRegion(value.id)}>{value?.name}</Text>
        ),
      },
      {
        Header: t('#members'),
        accessor: 'nonDeactivatedMembersCount',
      },
      {
        Header: t('dateAdded'),
        accessor: 'createdAt',
        Cell: ({ value }) => getDateReadableDayMonthYear({ date: value }),
      },
      {
        Header: t('actions'),
        accessor: 'id',
        Cell: ({
          row: {
            original: { id },
          },
        }) => (
          <Tooltip
            options={[
              { label: t('edit'), value: 'edit' },
              ...(query.statusId !== 1
                ? [{ label: t('activate'), value: 'activate' }]
                : [
                    { label: 'Suspend', value: 'suspend' },
                    { label: t('deactivate'), value: 'deactivate' },
                  ]),
              ...(query.statusId !== 4
                ? [{ label: t('delete'), value: 'delete' }]
                : []),
            ]}
            onClick={({ value }) => onClickAction({ action: value, id })}
          />
        ),
      },
    ],
    [onClickAction, onClickRegion, query.statusId, t]
  );

  return <Table {...props} columns={tableColumns} data={tableData} />;
};
