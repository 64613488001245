import { Modal } from 'octiv-components';
import PackageBulkActions from 'octiv-containers/members/packages/PackageBulkActions';
import Setting from 'octiv-containers/settings/components/Setting';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { useToggle } from 'octiv-hooks';
import {
  usePackagesFind,
  usePackagesUpdateBulkActions,
} from 'octiv-hooks/requests/Packages';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import FormNoShowAndCancellationFee from './FormNoShowAndCancellationFee';
import FormPackageList from './FormPackageList';

export default () => {
  const { t } = useTranslation();
  const { selectedLocation } = useActiveUserTenant();

  const [packages, setPackages] = useState([]);
  const [toggle, setToggle, resetToggle] = useToggle();

  const packagesFind = usePackagesFind({
    filter: { isActive: 1, locationId: selectedLocation?.id },
    paging: { perPage: -1 },
  });

  const packagesBulkActions = usePackagesUpdateBulkActions({
    onSuccess: (res) => {
      console.log({ res });
      resetToggle();
    },
    meta: {
      useOnSuccessToast: true,
    },
  });

  const onClickAction = ({ action }) => {
    setToggle({
      type: action,
      data: packages,
    });
  };

  const handleSubmit = (values) => {
    packagesBulkActions.mutate({
      [toggle.type === 'noShowFee' ? 'noShowFee' : 'lateCancellationFee']:
        values.fee,
      packageIds: packages,
    });
  };

  React.useEffect(() => {
    console.log({ packages });
  }, [packages]);

  return (
    <Setting title={t('fees')}>
      {toggle.isVisible && (
        <Modal
          isSidebar
          title={
            toggle.type === 'noShowFee'
              ? t('noShowFee')
              : t('lateCancellationFee')
          }
          onClose={resetToggle}
        >
          <FormNoShowAndCancellationFee
            type={toggle.type}
            onSubmit={handleSubmit}
          />
        </Modal>
      )}
      <PackageBulkActions
        mb={4}
        selectedData={packages}
        onClickAction={onClickAction}
      />

      <FormPackageList
        packages={packagesFind?.data?.data || []}
        selectedPackages={packages}
        setSelected={setPackages}
      />
    </Setting>
  );
};
