import { Formik } from 'formik';
import { Alert, Button, Field } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({ isLoading, initialValues = {}, ...props }) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      enableReinitialize
      initialValues={{
        cref: initialValues.cref || undefined,
        devId: initialValues.devId || undefined,
        devToken: initialValues.devToken || undefined,
      }}
      validationSchema={Yup.object().shape({
        cref: Yup.string(),
        devId: Yup.string(),
        devToken: Yup.string(),
      })}
    >
      {({ handleSubmit, dirty }) => (
        <form onSubmit={handleSubmit}>
          <Alert
            mb={4}
            subtitle={t('ensureKeysAreCorrect')}
            variant='warning'
          />

          <Field boxContainerProps={{ mb: 4 }} label='CREF' name='cref' />

          <Field boxContainerProps={{ mb: 4 }} label='Dev ID' name='devId' />

          <Field
            boxContainerProps={{ mb: 4 }}
            label='Dev Token'
            name='devToken'
          />

          <Button
            isDisabled={!dirty}
            isLoading={isLoading}
            text={t('submit')}
            type='submit'
          />
        </form>
      )}
    </Formik>
  );
};
