export default [
  {
    date: '2023-07-27',
    content: [
      {
        title: 'Graph Data Enhancements',
        body: "Now you can choose which data to display on a graph when there's too much information to show all at once.",
      },
      {
        title: 'Lead Export',
        body: 'Notes regarding the lead have been added to the lead export.',
      },
      {
        title: 'GoCardless Payouts',
        body: 'GoCardless payouts can now be viewed within the Octiv Accounts section.',
      },
      {
        title: 'Paystack Fees',
        body: 'When viewing a settlement, you can now see the associated fees.',
      },
      {
        title: 'Paystack API Error Handling',
        body: 'If the Paystack API fails to respond when retrieving a settlement, a more user-friendly message will be displayed along with a button to allow you to retry the request. We have logged this issue with Paystack.',
      },
    ],
  },
  {
    date: '2023-06-29',
    content: [
      {
        title: 'User Interface Enhancements',
        body: 'You will notice a much better experience when using the platform on a mobile device, especially when viewing the whiteboard and a single class. We hope you enjoy these changes!',
      },
      {
        title: 'Prevent certain packages from being assigned to members',
        body: 'When creating or editing a package, you can "Prevent staff from assigning this package to members". Enable this setting if you wish to keep a package active but do not want it to appear as an option when assigning a new package to members.',
      },
      {
        title: 'Drop-In Payment & Booking',
        body: 'Once a drop-in pays their invoice, the link for them to make their booking will be displayed on the invoice screen.',
      },
      {
        title: 'Whiteboard Sorting',
        body: "You can now sort and group the whiteboard by exercise, RX/Scaled, and gender. We'd really love to hear your feedback on this one, so please let us know what you think!",
      },
      {
        title: 'Future Bookings on Member Packages',
        body: 'You can now see how many future bookings a member has for a certain package on the Members => Packages screen.',
      },
    ],
  },
  {
    date: '2023-05-09',
    content: [
      {
        title: 'Waiver Updates',
        body: 'We refined how waiver actions are communicated on the platform so that lead and member waivers work similarly.',
      },
    ],
  },
  {
    date: '2023-04-26',
    content: [
      {
        title: 'German Translation',
        body: 'We have added the ability for our German-speaking users to use Octiv in their native language! This is our first release that caters to a language other than English, so please let us know if you have any feedback.',
      },
      {
        title: 'Removed gender specification requirement',
        body: "You no longer need to specify your or your member's gender when signing up or editing your profile on Octiv",
      },
    ],
  },
  {
    date: '2023-03-07',
    content: [
      {
        title:
          'Send a communication from almost everywhere a user is listed in the platform',
        body: 'You will now find a chat-like icon or a Send Comm button throughout the platform. Clicking on these will redirect you to the create communication screen with a populated list of recipients. This feature is only visible if your staff account has the Comms permission enabled.',
      },
      {
        title:
          'Addition of Total Invoiced to the Reports Dashboard Accounts section',
      },
    ],
  },
  {
    date: '2023-02-27',
    content: [
      {
        title: 'User interface refinements',
        body: "These updates aim to solve a few visual and usability issues within the system. Nothing too major, but we feel you'll enjoy these enhancements as a whole. Here are the highlights:",
        bullets: [
          'A new, sleeker font.',
          'Refined color schemes for both light and dark themes.',
          'A less "boxy" appearance that aims to allow for data to be more visually front and centre.',
          'A new graphing system that is not only better looking but allows for greater data interactivity.',
          'A new date selector that is far more user-friendly than before.',
        ],
      },
      {
        title: 'A major reporting upgrade',
        body: "We're releasing this reporting upgrade as a beta feature. We encourage you to take some time to look through them and consider what extra data or insights you may want as a business. We look forward to your feedback on these, so please get in touch. Here are the key takeaways:",
        bullets: [
          'The Accounts Dashboard has been moved to the Reports Dashboard.',
          'The Reports Dashboard caters to four separate sections, namely Accounts, Scheduling, Members, and Leads.',
          'In many of these sections, you will find we have broken up the data into months so that key insights can be more easily tracked and drawn from the data.',
          'One of our favourite new reports is the "Most Popular Booking Times" report. This report allows you to see how active your gym is at different times of the day and can be used to help you make decisions on how to fill those slower time slots.',
          'Apart from a refreshed Dashboard, we have a few newer and a few well-refined reports too.',
          'The Package Revenue report has been reworked to give you greater insights into how your packages perform over a specified period. We see this as a fantastic way of deciding on what works and what may require some more attention.',
          'Class Attendance was fleshed out to display more insightful and valuable information about classes.',
          'Member Non-Attendance was updated with a "last attended date”, simple yet useful.',
          'Attendance Overview now allows you to filter by year.',
        ],
      },
      {
        title: 'Package categories and priority',
        body: 'Allowing potential members to easily understand your offering is a must. With package categories, you can now group your packages and display them in a way that makes sense to those that are viewing your sign-up widget. You can also set a priority on each package so that the most important packages are displayed first within their category.',
      },
      {
        title: 'Payment processors',
        body: 'Many of the gyms on our platform, potentially just like yours, use a separate payment processor to process in-person payments. We have now added the ability to tag payments on Octiv using a custom processor to give you greater insights into your business. To set up a custom processor, go to Settings => Accounts => Payment Processors and click on "Create Payment Processor". You can then apply this processor when creating or updating a payment.',
      },
      {
        title: 'Location tags',
        body: 'This feature allows you to set up tags for your locations and apply these tags to classes. This is helpful in cases where a Yoga studio may have one heated and one non-heated room in a single location. They can now create two tags for this location (Heated Room, Non-Heated Room) and then apply this tag to the classes that will be in those rooms accordingly.',
      },
      {
        title: 'New Member Packages view',
        body: 'This feature is very helpful in identifying which member packages are coming to an end so you can proactively reach out to them to renew.',
      },
      {
        title:
          'Class names for single instances of recurring classes can now be changed',
        body: 'You can now change the name of class instances. We know there are many of you out there who will greatly appreciate this enhancement!',
      },
      {
        title: 'Updated package creation screen',
        body: "We've updated this screen to give your guidelines on what is what. Nothing too major.",
      },
    ],
  },
  {
    date: '2022-11-28',
    content: [
      {
        title: 'Package Creation Workflow',
        body: 'To simplify your user experience, we have added the ability to generate an invoice when adding a package to a member.',
      },
      {
        title: 'Class Booking Workflow',
        body: 'In line with the above update to the package creation workflow, you can now add packages to members within the class booking modal who do not have an assigned package for that class. To do this, search for the member and click on "Add Package".',
      },
    ],
  },
  {
    date: '2022-10-20',
    content: [
      {
        title: 'Sessions remaining for drop-ins',
        body: "Drop-in's used to have no way of knowing how many sessions they had left when making their bookings. We've added a handy countdown to keep them in the know, and once their sessions reach 0, we prompt them to purchase more.",
      },
      {
        title: 'Minor, but impactful, interface updates',
        body: "On this one, we need to give a shout-out to you, our clients, as without your valuable input, these would have stayed below our radar. Here are two updates we'd like to make special mention of:",
        bullets: [
          'Calendar days have been formatted to "Thu 20" instead of "Thu 10/20”. Why must we confuse you on days like "Tue 10/11”? Is it the 10th, or is it the 11th - who really knows?',
          'The workout duplication message is now a lot more clear about what needs to be done next. Hard Work Pays Off, but sometimes minor adjustments do too.',
        ],
      },
    ],
  },
  {
    date: '2022-10-11',
    content: [
      {
        title: 'Discovery Vitality Check-In QR Code',
        body: "We've refined our logic for updating codes to mitigate a few scenarios where a code may become invalid.",
      },
      {
        title: 'App Update Prompts',
        body: "In the future, you may notice a prompt that states that you need to refresh your browser window in order to update to the latest version of Octiv. We have done this to ensure that you are always running the latest version of Octiv and don't miss any important updates that we release.",
      },
    ],
  },
  {
    date: '2022-08-23',
    content: [
      {
        title: 'Updated workflow for on-hold members',
        body: 'When placing a member on hold, you now have the ability to extend their package end dates by the number of days they are on hold for. While building out this feature, we also incorporated other minor tweaks to make it more user-friendly in general.',
      },
    ],
  },
  {
    date: '2022-08-04',
    content: [
      {
        title: 'Paystack is live!',
        body: "After doing a deep dive into the best payment gateways available in South Africa, we came to the conclusion that Paystack, which was acquired by Stripe to be their African arm, was the best for us and our clients' needs. All South African facilities can now enable Paystack as their ad hoc payment gateway of choice.",
      },
    ],
  },
  {
    date: '2022-07-25',
    content: [
      {
        title: 'Major update to adding recipients in Comms',
        body: 'Adding recipients in communications used to be a hassle. We rethought the experience and created a much sleeker and more user-friendly approach. We hope you like it.',
      },
    ],
  },
  {
    date: '2022-07-22',
    content: [
      {
        title: 'Ability to delete a deactivated staff member',
        body: 'If a staff member is deactivated and you want to remove them entirely, now you can.',
      },
      {
        title: 'The "Log Result" button is now easier to see on the Whiteboard',
        body: "We're not sure why we made it so difficult to see in the first place. Sorry about that.",
      },
    ],
  },
  {
    date: '2022-07-20',
    content: [
      {
        title: 'Netcash PayNow payment reattempts',
        body: 'We updated how we send references to Netcash PayNow so that users can reattempt paying for invoices after their initial payment attempt was declined due to issues Netcash was having.',
      },
      {
        title: 'Discovery Vitality information added to Check-In QR Code',
        body: "If you are viewing a Discovery Vitality enabled location's check-in screen, you will notice some more information related to points earning is displayed.",
      },
    ],
  },
  {
    date: '2022-07-18',
    content: [
      {
        title: 'New columns added to member export',
        body: 'The last modified and deactivated dates were added to the member export.',
      },
    ],
  },
  {
    date: '2022-06-20',
    content: [
      {
        title:
          'Extra validation applied to Discovery Vitality check-in/out flow',
        body: 'Users were inputting numbers and other characters into the name and surname fields which invalidated their ability to earn Vitality points. They can now only input valid characters.',
      },
    ],
  },
  {
    date: '2022-06-08',
    content: [
      {
        title: 'Unpaid invoice summary',
        body: 'We have simplified the unpaid invoice design to make it easier for users to see the amount due and pay it.',
      },
    ],
  },
  {
    date: '2022-06-06',
    content: [
      {
        title: 'Manage member packages while adding bookings',
        body: "When adding a booking to a class, you can now easily manage a member's packages when they have no sessions available.",
      },
      {
        title: 'Whiteboard sort order',
        body: 'Whiteboard items without a score are now sorted by class start time and then member name.',
      },
      {
        title: 'Add bookings to a class from the Whiteboard',
        body: 'You will find an "Add Bookings" button in the top right of the screen on the Whiteboard. Filter the Whiteboard by class to enable it.',
      },
      {
        title: 'Performance enhancements',
        body: "We made a few behind the scenes enhancements that improve Octiv's initial load time.",
      },
    ],
  },
  {
    date: '2022-04-29',
    content: [
      {
        title: 'Addition of extra payment button on invoice',
        body: "We've added another payment button to invoices so that one displays at the top and one at the bottom of the invoice.",
      },
    ],
  },
  {
    date: '2022-03-31',
    content: [
      {
        title: 'Added class trainer to member attendance report',
      },
    ],
  },
  {
    date: '2022-03-21',
    content: [
      {
        title: 'Limited packages removed from debit batches',
        body: 'A highly requested improvement: limited packages will no longer be included in debit batches.',
      },
      {
        title: 'Cancelling a class booking',
        body: "Previously, when cancelling a member's booking the session would be refunded by default. You now have the option to use the cancellation threshold of the class which will determine whether the session is refunded or not.",
      },
      {
        title: 'Class booking details',
        body: 'Class bookings now all have an "ℹ" icon that when hovered on will display the created and updated by with the time.',
      },
      {
        title: 'Roster filter for all cancellations',
        body: 'On the Roster you are now able to filter by an "All Cancellations" status.',
      },
    ],
  },
];
